export interface SourceFile {
  url: string;
  integrity?: string;
}

export interface JavasciptSourceFile extends SourceFile {
  module?: boolean;
}

export interface LocalConfig {
  js: JavasciptSourceFile[];
  css: SourceFile[];
}

export interface Bundle {
  id: string;
  localConfig: LocalConfig;
}

export const bundles: Bundle[] = [
  {
    id: 'notifications',
    localConfig: {
      js: [{ url: '/notifications/static/index.js' }],
      css: [],
    },
  },
  {
    id: 'dr-strange',
    localConfig: {
      js: [{ url: '/dr-strange/static/index.js' }],
      css: [],
    },
  },
  {
    id: 'fleet-insights',
    localConfig: {
      js: [{ url: '/fleet-insights/c4h-bundle.js' }],
      css: [],
    },
  },
  {
    id: 'korg',
    localConfig: {
      js: [{ url: '/korg/static/index.js' }],
      css: [],
    },
  },
  {
    id: 'sam',
    localConfig: {
      js: [{ url: '/sam/static/main.js' }],
      css: [],
    },
  },
  {
    id: 'limits',
    localConfig: {
      js: [{ url: '/limits/static/main.js' }],
      css: [{ url: '/limits/static/style.css' }],
    },
  },
  {
    id: 'groot',
    localConfig: {
      js: [{ url: '/groot/static/index.js' }],
      css: [],
    },
  },
  {
    id: 'productivity',
    localConfig: {
      js: [{ url: '/productivity/static/main.js' }],
      css: [],
    },
  },
  {
    id: 'operations',
    localConfig: {
      js: [{ url: '/operations/static/index.js' }],
      css: [],
    },
  },
  {
    id: 'service-reports',
    localConfig: {
      js: [{ url: '/service-reports/static/index.js' }],
      css: [],
    },
  },
  {
    id: 'preopcheck',
    localConfig: {
      js: [{ url: '/preopcheck/static/index.js' }],
      css: [{ url: '/preopcheck/static/index.css' }],
    },
  },
  {
    id: 'data-insights',
    localConfig: {
      js: [{ url: '/data-insights/static/main.js' }],
      css: [],
    },
  },
  {
    id: 'helpcentre',
    localConfig: {
      js: [{ url: '/helpcenter/assets/index.js', module: true }],
      css: [],
    },
  },
  {
    id: 'jh-welcome',
    localConfig: {
      js: [{ url: '/welcome/assets/index.js', module: true }],
      css: [],
    },
  },
  {
    id: 'maintenance',
    localConfig: {
      js: [{ url: '/maintenance/static/index.js' }],
      css: [],
    },
  },
  {
    id: 'soc',
    localConfig: {
      js: [{ url: '/soc/static/index.js', module: true }],
      css: [],
    },
  },
  {
    id: 'api-selfservice',
    localConfig: {
      js: [{ url: '/api-selfservice/static/main.js', module: true }],
      css: [],
    },
  },
  {
    id: 'jh-acd-support-tool',
    localConfig: {
      js: [{ url: '/acd-support-tool/assets/index.js', module: true }],
      css: [],
    },
  },
  {
    id: 'jh-acd-connect-modal',
    localConfig: {
      js: [{ url: '/acd-connect-modal/assets/index.js', module: true }],
      css: [],
    },
  },
];

if (window.fury.flag.ENABLE_RELEASE_NOTES) {
  bundles.push({
    id: 'jh-release-notes',
    localConfig: {
      js: [{ url: '/release-notes/assets/main.js', module: true }],
      css: [],
    },
  });
}

export default bundles;
